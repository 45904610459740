<script>
import _merge from "lodash/merge";
import _isEqual from "lodash/isEqual";
import listingBox from "@/components/listing-box.vue";
import { wpService } from "@/services/wp";
import { mapGetters } from "vuex";
export default {
  components: {
    listingBox,
  },
  props: {
    entry: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      editing: false,
      clonedAmenities: [],
    };
  },
  created() {
    this.reset();
  },
  computed: {
    ...mapGetters("config", ["get_amenities", "listings_amenities"]),
    amenities() {
      return this.get_amenities(this.entry.ltg_amenities);
    },
    canSubmit() {
      if (!_isEqual(this.clonedAmenities, this.entry.ltg_amenities))
        return { amenities: this.clonedAmenities };
      return false;
    },
  },
  methods: {
    reset() {
      this.clonedAmenities = _merge([], this.entry.ltg_amenities);
    },
    submit() {
      let loading = this.$buefy.loading.open({ container: null });
      wpService.listings
        .patchSingle(this.entry.ltg_id, this.canSubmit)
        .then(() => {
          this.editing = false;
          this.$buefy.toast.open({
            message: "¡Comodidades actualizadas!",
            type: "is-success",
          });
          loading.close();
        })
        .catch(err => {
          console.log(err);
          loading.close();
        });
    },
  },
  watch: {
    editing() {
      this.reset();
    },
  },
};
</script>

<template>
  <listingBox
    title="Comodidades"
    editable
    :editing="editing"
    @editing="editing = $event"
  >
    <b-message type="is-warning" v-if="!amenities.length">
      <b-icon icon="eye" />
      <span><b>Recomendado: </b>Especificar etiquetas.</span>
    </b-message>
    <div v-if="editing">
      <div class="amenities">
        <div
          class="amenity"
          v-for="amenity in listings_amenities"
          :key="amenity.amenity_id"
        >
          <b-checkbox
            v-model="clonedAmenities"
            :native-value="amenity.amenity_id"
          >
            {{ amenity.amenity_label_es }}
          </b-checkbox>
        </div>
      </div>
      <div class="submit">
        <b-button type="is-primary" :disabled="!canSubmit" @click="submit">
          Guardar
        </b-button>
      </div>
    </div>
    <ul v-else class="amenities">
      <li
        v-for="amenity in amenities"
        :key="amenity.amenity_id"
        class="amenity"
      >
        <b-icon icon="circle" type="is-primary" />
        <span class="da__value">{{ amenity.amenity_label_es }}</span>
      </li>
    </ul>
  </listingBox>
</template>

<style lang="scss" scoped>
.submit {
  margin-top: 1rem;
}
.amenity {
  display: flex;
  margin-bottom: 0.25rem;
  .icon {
    width: 0.5rem;
    font-size: 0.5rem;
    margin-right: 0.75rem;
  }
}
@media screen and (min-width: 40em) {
  .amenities {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
</style>
